import { firstCapAndSplit } from './javascript'
import { emailValidation } from './regex'

const emailVal = (value, msg) => {
  if (value) {
    if (!emailValidation(value)) {
      return `Enter a valid ${msg}`
    }
  }

  return false
}

const checkProjectName = (value, msg) => {
  if (value) {
    const startSpace = /\s$/
    const endSpace = /^\s/
    if (startSpace.test(value) || endSpace.test(value)) {
      return `Enter a valid ${msg}`
    }
  }
  return false
}
const amountNotMoreThan = (value, msg) => {
  if (value) {
    if (value.length > 11) {
      return `Enter a  ${firstCapAndSplit(msg)} not more than 11 digits`
    }
  }
  return false
}

const updateProjectDetailValidation = (name, value) => {
  switch (name) {
    case 'projectAdminEmail':
      return emailVal(value, 'Project Admin Email')
    case 'projectNetworkProductionEmail':
      return emailVal(value, 'Project Network Production Email')
    case 'projectNetworkFinanceEmail':
      return emailVal(value, 'Project Network Finance Email')
    case 'projectBankAccountContactEmail':
      return emailVal(value, 'Project Bank Account Contact Email')
    case 'projectPayrollContactEmail':
      return emailVal(value, 'Project Payroll Contact Email')
    case 'projectName':
      return checkProjectName(value, 'projectName')
    case 'budget':
    case 'efc':
    case 'projectApprovedOverUnder':
    case 'projectFundingToDate':
    case 'projectInvoicedToDate':
    case 'insuranceClaims':
    case 'anticipatedIncentive':
    case 'projectBankBalancePerBook':
    case 'projectBankBalancePerBank':
    case 'projectOutStandingChecks':
    case 'projectResidualsTalent':
    case 'projectResidualsOther':
    case 'projectBonusDue':
      return amountNotMoreThan(value, name)
    default: {
      return ''
    }
  }
}

export default updateProjectDetailValidation
