export const emailValidation = (email) => {
  const regex =
    /^[a-zA-Z\d]{2,}((\.|-|_|\$|\+)[a-zA-Z\d]{2,})*@[a-zA-Z\d]{2,}(((\.|-)[a-zA-Z\d]{2,})*(\.[a-zA-Z]{2,}))+$/
  return regex.test(email.toLowerCase())
}

export const passwordValidation = (password) => password.length > 7

export const confirmPassword = (confirmPass, pass) => confirmPass === pass

export const alphabeticStringValidation = (val) => {
  const regex = /^[A-Za-z\-_]+$/
  return regex.test(val)
}
export const alphabeticStringUseUnderscoreValidation = (val) => {
  const regex = /^[A-Za-z0-9-]([\w -]*[A-Za-z0-9-])?$/
  return regex.test(val)
}

export const isEmpty = (value) => (value !== undefined ? value : '')

export const isEmptyString = (value) => value === ''

export const number = (val) => {
  const regex = /^[0-9]+$/
  return regex.test(val)
}

export const mobileNumber = (val) => {
  const regex = /^[0-9]+$/
  return regex.test(val) && val.toString().length === 10
}

export const values = (object) => (object ? Object.values(object) : [])

export const entries = (object) => (object ? Object.entries(object) : [])

export const keys = (object) => (object ? Object.keys(object) : [])

export const stringValue = (val) => {
  const regex = /^[a-zA-Z]([\w -]*[a-zA-Z])?$/
  return regex.test(val)
}
export const stringValueWithAcceptDotAndCommas = (val) => {
  const regex = /^[A-Za-z.,]+(?:\s+[A-Za-z.,]+)*$/
  return regex.test(val)
}

// export const stringValue = (val) => {
//   const regex = /^[A-Za-z\-_ ]+$/
//   return regex.test(val)
// }

export const firstLetterCap = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export const noSpace = (val) => {
  const regex = /^\S+$/
  return regex.test(val)
}

// export const noSpace = (val) => {
//   const regex = /^([A-Z][a-z]+)$/
//   return regex.test(val)
// }

export const phoneFormats = [
  /^(\d{10})$/,
  /^\(\d{3}\)\d{3}-\d{4}$/,
  /^\d{3}-\d{3}-\d{4}$/,
  /^\d{3}\.\d{3}\.\d{4}$/,
]

export const phoneNumberAcceptRegex = (val) => {
  const regex = /[0-9(),-.]/
  return regex.test(val)
}
export const costReportInputRegex = (val) => {
  const regex = /^\d*\.?\d*$/
  return regex.test(val)
}

export const qbBudgetRegex = (val) => {
  const regex = /^[0-9]*$/
  return regex.test(val)
}
