import { gql } from '@apollo/react-hooks'

export const ADD_PURCHASE_ORDER = gql`
  mutation createPurchaseOrder(
    $formName: RequestType!
    $vendorName: String!
    $commitment: Float!
    $projectId: String!
    $company: String!
    $accountSubtotals: [AccountSubtotalInput!]!
    $qualifiedExpense: String
    $covidCost: Boolean
    $sets: String
    $pickUpDate: String
    $episode: String
    $returnDate: String
    $anticipatedCheckInDate: String
    $anticipatedCheckoutDate: String
    $guestName: String
    $arrivalLocation: String
    $departureLocation: String
    $passengerName: String
    $contractUpload: Upload
    $w9Upload: Upload
    $comments: String
    $description: String
    $assignedApprover: ID!
  ) {
    purchaseOrder {
      createPurchaseOrder(
        purchaseOrderData: {
          formName: $formName
          vendorName: $vendorName
          commitment: $commitment
          projectId: $projectId
          company: $company
          qualifiedExpense: $qualifiedExpense
          sets: $sets
          comments: $comments
          description: $description
          accountSubtotals: $accountSubtotals
          contractUpload: $contractUpload
          w9Upload: $w9Upload
          covidCost: $covidCost
          episode: $episode
          assignedApprover: $assignedApprover
          purchaseOrderInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            hotel: {
              anticipatedCheckInDate: $anticipatedCheckInDate
              anticipatedCheckoutDate: $anticipatedCheckoutDate
              guestName: $guestName
            }
            travel: {
              arrivalLocation: $arrivalLocation
              departureLocation: $departureLocation
              passengerName: $passengerName
            }
          }
        }
      ) {
        error
        success
        message
      }
    }
  }
`

export const ADD_WIRE_TRANSFER = gql`
  mutation createWireTransfer(
    $formName: RequestType!
    $vendorName: String!
    $projectId: String!
    $company: String!
    $qualifiedExpense: String
    $accountSubtotals: [AccountSubtotalInput!]!
    $pickUpDate: String
    $returnDate: String
    $locationRentalEnd: String
    $covidCost: Boolean
    $episode: String
    $locationRentalStart: String
    $sets: String
    $amount: Float!
    $bankAccountNumber: String!
    $routingNumber: String!
    $currency: String
    $bankAddress: String
    $bankName: String!
    $accountName: String!
    $comments: String
    $contractUpload: Upload
    $invoice: Upload
    $w9Upload: Upload
    $purchaseOrderNumber: String
    $invoiceNumber: String
    $description: String
    $swiftCode: String
    $additionalBankNotes: String
    $bankInfo: String
    $dateRequired: DateTime!
    $international: Boolean
    $assignedApprover: ID!
  ) {
    wireTransfer {
      createWireTransfer(
        wireTransferInput: {
          formName: $formName
          vendorName: $vendorName
          amount: $amount
          projectId: $projectId
          company: $company
          accountSubtotals: $accountSubtotals
          accountName: $accountName
          bankInfo: {
            bankAccountNumber: $bankAccountNumber
            bankAddress: $bankAddress
            bankName: $bankName
            routingNumber: $routingNumber
            swiftCode: $swiftCode
            additionalBankNotes: $additionalBankNotes
            bankInfo: $bankInfo
            currency: $currency
          }
          wireTransferInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            locationRental: {
              locationRentalEnd: $locationRentalEnd
              locationRentalStart: $locationRentalStart
            }
          }
          comments: $comments
          contractUpload: $contractUpload
          dateRequired: $dateRequired
          description: $description
          invoice: $invoice
          invoiceNumber: $invoiceNumber
          purchaseOrderNumber: $purchaseOrderNumber
          qualifiedExpense: $qualifiedExpense
          sets: $sets
          w9Upload: $w9Upload
          covidCost: $covidCost
          episode: $episode
          international: $international
          assignedApprover: $assignedApprover
        }
      ) {
        error
        message
        success
      }
    }
  }
`

export const ADD_CHECK_REQUEST = gql`
  mutation createCheckRequest(
    $formName: RequestType!
    $vendorName: String!
    $projectId: String!
    $company: String!
    $amount: Float!
    $qualifiedExpense: String
    $accountSubtotals: [AccountSubtotalInput!]!
    $comments: String
    $pickUpDate: String
    $returnDate: String
    $covidCost: Boolean
    $episode: String
    $locationRentalEnd: String
    $locationRentalStart: String
    $contractUpload: Upload
    $dateRequired: DateTime!
    $description: String
    $invoice: Upload
    $invoiceNumber: String
    $purchaseOrderNumber: String
    $sets: String
    $w9Upload: Upload
    $assignedApprover: ID!
  ) {
    checkRequest {
      createCheckRequest(
        checkRequestInput: {
          formName: $formName
          vendorName: $vendorName
          amount: $amount
          projectId: $projectId
          company: $company
          accountSubtotals: $accountSubtotals
          comments: $comments
          checkRequestInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            locationRental: {
              locationRentalEnd: $locationRentalEnd
              locationRentalStart: $locationRentalStart
            }
          }
          contractUpload: $contractUpload
          dateRequired: $dateRequired
          description: $description
          invoice: $invoice
          invoiceNumber: $invoiceNumber
          purchaseOrderNumber: $purchaseOrderNumber
          qualifiedExpense: $qualifiedExpense
          sets: $sets
          w9Upload: $w9Upload
          covidCost: $covidCost
          episode: $episode
          assignedApprover: $assignedApprover
        }
      ) {
        error
        message
        success
      }
    }
  }
`

export const ADD_CREDIT_CARD_CHARGE = gql`
  mutation createCreditCardCharge(
    $formName: RequestType!
    $vendorName: String!
    $projectId: String!
    $companyId: String!
    $accountSubtotals: [AccountSubtotalInput!]!
    $invoiceUpload: Upload
    $w9Upload: Upload
    $sets: String
    $comments: String
    $episode: String
    $qualifiedExpense: String
    $covidCost: Boolean
    $description: String
    $creditCardAccountName: String!
    $creditCardNumber: String!
    $pickUpDate: String
    $returnDate: String
    $anticipatedCheckInDate: String
    $anticipatedCheckoutDate: String
    $guestName: String
    $arrivalLocation: String
    $departureLocation: String
    $passengerName: String
    $commitment: Float!
    $assignedApprover: ID!
  ) {
    creditCardCharge {
      createCreditCardCharge(
        creditCardChargeInput: {
          formName: $formName
          commitment: $commitment
          submissionInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            hotel: {
              anticipatedCheckInDate: $anticipatedCheckInDate
              anticipatedCheckoutDate: $anticipatedCheckoutDate
              guestName: $guestName
            }
            travel: {
              arrivalLocation: $arrivalLocation
              departureLocation: $departureLocation
              passengerName: $passengerName
            }
          }
          creditCardAccountName: $creditCardAccountName
          creditCardNumber: $creditCardNumber
          projectId: $projectId
          companyId: $companyId
          accountSubtotals: $accountSubtotals
          comments: $comments
          invoiceUpload: $invoiceUpload
          description: $description
          qualifiedExpense: $qualifiedExpense
          sets: $sets
          w9Upload: $w9Upload
          vendorName: $vendorName
          covidCost: $covidCost
          episode: $episode
          assignedApprover: $assignedApprover
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($purchaseOrderId: String!) {
    purchaseOrder {
      deletePurchaseOrder(purchaseOrderId: $purchaseOrderId) {
        error
        message
        success
      }
    }
  }
`

export const DELETE_WIRE_TRANSFER = gql`
  mutation deleteWireTransfer($wireTransferId: String!) {
    wireTransfer {
      deleteWireTransfer(wireTransferId: $wireTransferId) {
        error
        message
        success
      }
    }
  }
`

export const DELETE_CHECK_REQUEST = gql`
  mutation deleteCheckRequest($checkRequestId: String!) {
    checkRequest {
      deleteCheckRequest(checkRequestId: $checkRequestId) {
        error
        message
        success
      }
    }
  }
`

export const DELETE_CREDIT_CARD_CHARGE = gql`
  mutation deleteCreditCardCharge($creditCardChargeId: String!) {
    creditCardCharge {
      deleteCreditCardCharge(creditCardChargeId: $creditCardChargeId) {
        error
        message
        success
      }
    }
  }
`

export const DELETE_AP_INVOICE = gql`
  mutation deleteApInvoice($apInvoiceId: String!) {
    apInvoice {
      deleteApInvoice(apInvoiceId: $apInvoiceId) {
        error
        message
        success
      }
    }
  }
`

export const EDIT_PURCHASE_ORDER = gql`
  mutation editPurchaseOrder(
    $purchaseOrderId: String!
    $description: String
    $vendorName: String
    $comments: String
    $commitment: Float
    $accountSubtotals: [AccountSubtotalInput!]
    $w9Upload: Upload
    $contractUpload: Upload
    $proofOfPayment: Upload
    $sets: String
    $qualifiedExpense: String
    $covidCost: Boolean
    $arrivalLocation: String
    $episode: String
    $departureLocation: String
    $passengerName: String
    $anticipatedCheckInDate: String
    $anticipatedCheckoutDate: String
    $guestName: String
    $pickUpDate: String
    $returnDate: String
    $accountingSignOff: String
    $additionalNotes: String
    $inputFinance: Boolean
  ) {
    purchaseOrder {
      editPurchaseOrder(
        purchaseOrderInput: {
          purchaseOrderId: $purchaseOrderId
          vendorName: $vendorName
          w9Upload: $w9Upload
          sets: $sets
          accountingSignOff: $accountingSignOff
          additionalNotes: $additionalNotes
          inputFinance: $inputFinance
          commitment: $commitment
          qualifiedExpense: $qualifiedExpense
          accountSubtotals: $accountSubtotals
          proofOfPayment: $proofOfPayment
          description: $description
          contractUpload: $contractUpload
          comments: $comments
          episode: $episode
          covidCost: $covidCost
          purchaseOrderInfo: {
            travel: {
              arrivalLocation: $arrivalLocation
              departureLocation: $departureLocation
              passengerName: $passengerName
            }
            hotel: {
              anticipatedCheckInDate: $anticipatedCheckInDate
              anticipatedCheckoutDate: $anticipatedCheckoutDate
              guestName: $guestName
            }
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
          }
        }
      ) {
        error
        success
        message
      }
    }
  }
`

export const EDIT_WIRE_TRANSFER = gql`
  mutation editWireTransfer(
    $description: String
    $vendorName: String
    $amount: Float
    $accountSubtotals: [AccountSubtotalInput!]
    $comments: String
    $w9Upload: Upload
    $contractUpload: Upload
    $proofOfPayment: Upload
    $sets: String
    $qualifiedExpense: String
    $covidCost: Boolean
    $dateRequired: DateTime
    $invoice: Upload
    $invoiceNumber: String
    $purchaseOrderNumber: String
    $episode: String
    $bankAccountNumber: String!
    $routingNumber: String!
    $currency: String
    $bankAddress: String!
    $bankName: String!
    $pickUpDate: String
    $returnDate: String
    $locationRentalEnd: String
    $locationRentalStart: String
    $accountingSignOff: String
    $additionalNotes: String
    $accountName: String
    $swiftCode: String
    $bankInfo: String
    $additionalBankNotes: String
    $inputFinance: Boolean
    $international: Boolean
    $wireTransferId: ID!
  ) {
    wireTransfer {
      editWireTransfer(
        wireTransferInput: {
          wireTransferId: $wireTransferId
          wireTransferInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            locationRental: {
              locationRentalEnd: $locationRentalEnd
              locationRentalStart: $locationRentalStart
            }
          }
          comments: $comments
          vendorName: $vendorName
          amount: $amount
          accountName: $accountName
          accountingSignOff: $accountingSignOff
          additionalNotes: $additionalNotes
          inputFinance: $inputFinance
          accountSubtotals: $accountSubtotals
          contractUpload: $contractUpload
          dateRequired: $dateRequired
          description: $description
          proofOfPayment: $proofOfPayment
          invoice: $invoice
          invoiceNumber: $invoiceNumber
          purchaseOrderNumber: $purchaseOrderNumber
          qualifiedExpense: $qualifiedExpense
          sets: $sets
          w9Upload: $w9Upload
          episode: $episode
          covidCost: $covidCost
          international: $international
          bankInfo: {
            bankAccountNumber: $bankAccountNumber
            bankAddress: $bankAddress
            bankName: $bankName
            routingNumber: $routingNumber
            currency: $currency
            swiftCode: $swiftCode
            bankInfo: $bankInfo
            additionalBankNotes: $additionalBankNotes
          }
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const EDIT_CHECK_REQUEST = gql`
  mutation editCheckRequest(
    $description: String
    $vendorName: String
    $amount: Float
    $accountSubtotals: [AccountSubtotalInput!]
    $comments: String
    $w9Upload: Upload
    $contractUpload: Upload
    $proofOfPayment: Upload
    $sets: String
    $qualifiedExpense: String
    $covidCost: Boolean
    $dateRequired: DateTime
    $invoice: Upload
    $invoiceNumber: String
    $episode: String
    $purchaseOrderNumber: String
    $checkRequestId: ID!
    $pickUpDate: String
    $returnDate: String
    $locationRentalEnd: String
    $locationRentalStart: String
    $accountingSignOff: String
    $additionalNotes: String
    $inputFinance: Boolean
    $accountName: String
    $paid: String
  ) {
    checkRequest {
      editCheckRequest(
        checkRequestInput: {
          checkRequestId: $checkRequestId
          checkRequestInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            locationRental: {
              locationRentalEnd: $locationRentalEnd
              locationRentalStart: $locationRentalStart
            }
          }
          w9Upload: $w9Upload
          sets: $sets
          vendorName: $vendorName
          amount: $amount
          accountingSignOff: $accountingSignOff
          additionalNotes: $additionalNotes
          inputFinance: $inputFinance
          accountName: $accountName
          paid: $paid
          accountSubtotals: $accountSubtotals
          qualifiedExpense: $qualifiedExpense
          purchaseOrderNumber: $purchaseOrderNumber
          invoiceNumber: $invoiceNumber
          invoice: $invoice
          proofOfPayment: $proofOfPayment
          description: $description
          dateRequired: $dateRequired
          contractUpload: $contractUpload
          comments: $comments
          episode: $episode
          covidCost: $covidCost
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const EDIT_CREDIT_CARD_CHARGE = gql`
  mutation editCreditCardCharge(
    $description: String
    $vendorName: String
    $accountSubtotals: [AccountSubtotalInput!]
    $commitment: Float
    $qualifiedExpense: String
    $covidCost: Boolean
    $sets: String
    $invoiceUpload: Upload
    $w9Upload: Upload
    $proofOfPayment: Upload
    $comments: String
    $episode: String
    $creditCardChargeId: String!
    $pickUpDate: String
    $returnDate: String
    $anticipatedCheckInDate: String
    $anticipatedCheckoutDate: String
    $guestName: String
    $arrivalLocation: String
    $departureLocation: String
    $passengerName: String
    $creditCardAccountName: String
    $creditCardNumber: String
    $accountingSignOff: String
    $additionalNotes: String
    $inputFinance: Boolean
  ) {
    creditCardCharge {
      editCreditCardCharge(
        creditCardChargeInput: {
          creditCardChargeId: $creditCardChargeId
          submissionInfo: {
            equipmentRental: {
              pickUpDate: $pickUpDate
              returnDate: $returnDate
            }
            hotel: {
              anticipatedCheckInDate: $anticipatedCheckInDate
              anticipatedCheckoutDate: $anticipatedCheckoutDate
              guestName: $guestName
            }
            travel: {
              arrivalLocation: $arrivalLocation
              departureLocation: $departureLocation
              passengerName: $passengerName
            }
          }
          vendorName: $vendorName
          accountSubtotals: $accountSubtotals
          accountingSignOff: $accountingSignOff
          additionalNotes: $additionalNotes
          inputFinance: $inputFinance
          commitment: $commitment
          proofOfPayment: $proofOfPayment
          w9Upload: $w9Upload
          invoiceUpload: $invoiceUpload
          sets: $sets
          qualifiedExpense: $qualifiedExpense
          description: $description
          comments: $comments
          creditCardAccountName: $creditCardAccountName
          creditCardNumber: $creditCardNumber
          episode: $episode
          covidCost: $covidCost
        }
      ) {
        error
        message
        success
      }
    }
  }
`

export const SUBMISSION_APPROVAL = gql`
  mutation createApproval(
    $requestType: RequestType!
    $approvalRequestId: ID!
    $status: ApprovalRequestStatus!
    $description: String
  ) {
    approvalRequest {
      createApproval(
        approvalData: {
          requestType: $requestType
          approvalRequestId: $approvalRequestId
          status: $status
          description: $description
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const DELETE_SUBMISSION_APPROVAL = gql`
  mutation deleteApproval($approvalId: ID!) {
    approvalRequest {
      deleteApproval(approvalId: $approvalId) {
        error
        message
        success
      }
    }
  }
`

export const CREATE_AP_INVOICE = gql`
  mutation createApInvoice(
    $formName: RequestType!
    $vendorName: String!
    $amount: Float!
    $projectId: String!
    $companyId: String!
    $accountSubtotals: [AccountSubtotalInput!]!
    $contractUpload: Upload
    $invoiceUpload: Upload
    $w9Upload: Upload
    $purchaseOrderNumber: String
    $comments: String
    $description: String
    $poStatus: String
    $poComment: String
    $qualifiedExpense: String
    $sets: String
    $episode: String
    $covidCost: Boolean
    $assignedApprover: ID!
  ) {
    apInvoice {
      createApInvoice(
        apInvoiceInput: {
          formName: $formName
          vendorName: $vendorName
          amount: $amount
          accountSubtotals: $accountSubtotals
          projectId: $projectId
          companyId: $companyId
          description: $description
          comments: $comments
          purchaseOrderNumber: $purchaseOrderNumber
          invoice: $invoiceUpload
          contractUpload: $contractUpload
          w9Upload: $w9Upload
          poStatus: $poStatus
          poComment: $poComment
          qualifiedExpense: $qualifiedExpense
          sets: $sets
          episode: $episode
          covidCost: $covidCost
          assignedApprover: $assignedApprover
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const EDIT_AP_INVOICE = gql`
  mutation editApInvoice(
    $apInvoiceId: ID!
    $sets: String
    $covidCost: Boolean
    $episode: String
    $accountSubtotals: [AccountSubtotalInput!]
    $qualifiedExpense: String
    $vendorName: String
    $purchaseOrderNumber: String
    $comments: String
    $w9Upload: Upload
    $contractUpload: Upload
    $invoice: Upload
    $amount: Float
    $poStatus: String
    $poComment: String
    $inputFinance: Boolean
    $paid: String
    $paidDate: String
    $assignedApprover: ID!
    $proofOfPayment: Upload
  ) {
    apInvoice {
      editApInvoice(
        apInvoiceInput: {
          apInvoiceId: $apInvoiceId
          sets: $sets
          covidCost: $covidCost
          episode: $episode
          accountSubtotals: $accountSubtotals
          qualifiedExpense: $qualifiedExpense
          vendorName: $vendorName
          purchaseOrderNumber: $purchaseOrderNumber
          comments: $comments
          w9Upload: $w9Upload
          contractUpload: $contractUpload
          invoice: $invoice
          amount: $amount
          poStatus: $poStatus
          poComment: $poComment
          paid: $paid
          paidDate: $paidDate
          inputFinance: $inputFinance
          assignedApprover: $assignedApprover
          proofOfPayment: $proofOfPayment
        }
      ) {
        error
        message
        success
      }
    }
  }
`
export const QUICK_BOOK_ALL_DATA = gql`
  mutation createQBDocument(
    $userId: String!
    $documentId: String!
    $submissionType: RequestType!
    $vendorName: String!
    $APAccountRefName: String!
    $docNumber: String
    $accountSubtotals: [QBLine!]!
  ) {
    createQBDocument(
      userId: $userId
      documentId: $documentId
      requestType: $submissionType
      QBcreatedocumentInput: {
        Line: $accountSubtotals
        DocNumber: $docNumber
        VendorRef: { name: $vendorName }
        APAccountRef: { name: $APAccountRefName }
      }
    ) {
      data
      error
    }
  }
`

export const QUICK_BOOK_PO_DATA = gql`
  mutation createQBPurchaseOrder(
    $userId: String!
    $vendorName: String!
    $APAccountRefName: String!
    $accountSubtotals: [QBPurchaseOrderLine!]!
    $purchaseOrderId: String!
  ) {
    createQBPurchaseOrder(
      userId: $userId
      purchaseOrderId: $purchaseOrderId
      QBcreatepurchaseorderInput: {
        Line: $accountSubtotals
        APAccountRef: { name: $APAccountRefName }
        VendorRef: { name: $vendorName }
      }
    ) {
      data
      error
    }
  }
`

export const QUICK_BOOK_WT_DATA = gql`
  mutation createQBBill(
    $userId: String!
    $billId: String!
    $vendorName: String!
    $submissionType: RequestType!
    $accountSubtotals: [QBPurchaseOrderLine!]!
  ) {
    createQBBill(
      userId: $userId
      billId: $billId
      submissionType: $submissionType
      QBcreatebillInput: {
        Line: $accountSubtotals
        VendorRef: { name: $vendorName }
      }
    ) {
      data
      error
    }
  }
`

export const UPDATE_QUICK_BOOK_PO_DATA = gql`
  mutation updateQBPurchaseOrder(
    $userId: String!
    $purchaseOrderId: String!
    $accountSubtotals: [QBPurchaseOrderLine!]!
    $APAccountRefName: String!
    $vendorName: String!
    $Id: String!
    $SyncToken: String!
  ) {
    updateQBPurchaseOrder(
      userId: $userId
      purchaseOrderId: $purchaseOrderId
      QBupdatepurchaseorderInput: {
        Line: $accountSubtotals
        APAccountRef: { name: $APAccountRefName }
        VendorRef: { name: $vendorName }
        Id: $Id
        SyncToken: $SyncToken
      }
    ) {
      data
      error
    }
  }
`

export const UPDATE_QUICK_BOOK_DATA = gql`
  mutation UpdateQBDocument(
    $userId: String!
    $documentId: String!
    $submissionType: RequestType!
    $accountSubtotals: [QBLine!]!
    $APAccountRefName: String!
    $vendorName: String!
    $Id: String!
    $SyncToken: String!
    $docNumber: String
  ) {
    updateQBDocument(
      userId: $userId
      documentId: $documentId
      requestType: $submissionType
      QBupdatedocumentInput: {
        Line: $accountSubtotals
        APAccountRef: { name: $APAccountRefName }
        DocNumber: $docNumber
        VendorRef: { name: $vendorName }
        Id: $Id
        SyncToken: $SyncToken
      }
    ) {
      data
      error
    }
  }
`

export const MAP_ACCOUNT = gql`
  mutation MapAccounts($userId: String!, $companyId: String!) {
    mapAccounts(userId: $userId, companyId: $companyId)
  }
`
export const UPLOAD_FILE_FOR_OPEN_AI = gql`
  mutation UploadFile($file: Upload!, $projectId: ID!) {
    openAi {
      uploadFile(file: $file, projectId: $projectId) {
        error
        message
        success
      }
    }
  }
`

export const UPLOAD_FILE_MESSAGE = gql`
  mutation SendFileMessage($question: String!, $projectId: ID!) {
    openAi {
      sendFileMessage(question: $question, projectId: $projectId) {
        error
        result
      }
    }
  }
`
