import { equal, ternary } from './javascript'
import { stringValue, stringValueWithAcceptDotAndCommas } from './regex'

export const nullValue = (value) =>
  ternary(!value || value.trim() === '', true, false)

const nameValidation = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required`
  }
  if (!stringValue(value)) {
    return `Please enter valid ${msg}`
  }
  return false
}

const bankNameValidation = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required`
  }
  if (!stringValueWithAcceptDotAndCommas(value)) {
    return `Please enter valid ${msg}`
  }
  return false
}
const dateValidation = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required`
  }
  return false
}
const numberValidation = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required`
  }
  if (stringValue(value)) {
    return `Please enter valid ${msg}`
  }
  if (value.length !== 4) {
    return 'Credit card number should be 4 digit'
  }
  return false
}
const nullValidation = (value, msg) => {
  if (nullValue(value)) {
    return `${msg} is required`
  }
  return false
}

const updateSubmissionValidation = (name, value, formType) => {
  if (equal(formType, 'PURCHASE_ORDER')) {
    switch (name) {
      case 'description':
        return nullValidation(value, 'Description')
      default: {
        return ''
      }
    }
  }
  if (equal(formType, 'WIRE_TRANSFER')) {
    switch (name) {
      case 'bankName':
        return bankNameValidation(value, 'Bank Name')
      case 'accountName':
        return nameValidation(value, 'Account Name')
      case 'routingNumber':
        return dateValidation(value, 'Routing Number')
      case 'accountNumber':
        return dateValidation(value, 'Account Number')
      case 'dateRequired':
        return dateValidation(value, 'Date Required')
      case 'invoiceNumber':
        return dateValidation(value, 'Invoice Number')
      default: {
        return ''
      }
    }
  }
  if (equal(formType, 'CHECK_REQUEST')) {
    switch (name) {
      case 'dateRequired':
        return dateValidation(value, 'Date Required')
      case 'invoiceNumber':
        return dateValidation(value, 'Invoice Number')
      default: {
        return ''
      }
    }
  }
  if (equal(formType, 'CREDIT_CARD_CHARGE')) {
    switch (name) {
      case 'creditCardNumber':
        return numberValidation(value, 'creditCardNumber')
      case 'creditCardAccountName':
        return nameValidation(value, 'creditCardAccountName')
      default: {
        return ''
      }
    }
  }
  return ''
}

export default updateSubmissionValidation
