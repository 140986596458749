import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { equal, length } from '../utils/javascript'
import { keys } from '../utils/regex'
import submissionValidation from '../utils/submissionValidation'
import updateProjectDetailValidation from '../utils/updateProjectDetailVali'
import updateSubmissionValidation from '../utils/updateSubmissionValidation'
import formValidate from '../utils/validation'
import { projectDetailsKeyReplace } from '../utils/constants'

export const checkErrorList = (item) => {
  const validationErrors = {}
  keys(item).forEach((name) => {
    const err = formValidate(name, item[name])
    if (err && length(err)) {
      validationErrors[name] = err
    }
  })
  return validationErrors
}
export const checkSubmissionErrorList = (item, formType) => {
  const validationErrors = {}
  keys(item).forEach((name) => {
    const err = submissionValidation(name, item[name], formType, item)
    if (err && length(err)) {
      validationErrors[name] = err
    }
  })
  return validationErrors
}
export const checkUpdateSubmissionErrorList = (item, formType) => {
  const validationErrors = {}
  keys(item).forEach((name) => {
    const err = updateSubmissionValidation(name, item[name], formType)
    if (err && length(err)) {
      validationErrors[name] = err
    }
  })
  return validationErrors
}

export const reusableMutation = (mutation) => useMutation(mutation)

export const reusableUseQuery = (query) =>
  useQuery(query, {
    fetchPolicy: 'network-only',
  })

export const reusableLazyQuery = (query) =>
  useLazyQuery(query, {
    fetchPolicy: 'network-only',
  })

const setDateFormat = (date, month, year) => {
  let [d, m] = [date, month + 1]
  const y = year
  if (d < 10) d = `0${d}`
  if (m < 10) m = `0${m}`
  return { d, m, y }
}

export const dateMonthYear = (date, month, year) => {
  const { d, m, y } = setDateFormat(date, month, year)
  return `${d}-${m}-${y}`
}
export const yearDateMonth = (date, month, year) => {
  const { d, m, y } = setDateFormat(date, month, year)
  return `${y}-${m}-${d}`
}
export const dateMonthYears = (date, month, year) => {
  const { d, m, y } = setDateFormat(date, month, year)
  return `${d}/${m}/${y}`
}

export const convertDatee = (data) =>
  dateMonthYears(data.getDate(), data.getMonth(), data.getFullYear())

export const convertDate = (data) =>
  dateMonthYear(data.getDate(), data.getMonth(), data.getFullYear())

export const convertYMDtoDMY = (date) => {
  const newDate = new Date(date)
  return dateMonthYear(
    newDate.getDate(),
    newDate.getMonth(),
    newDate.getFullYear(),
  )
}
export const convertDMYtoYMD = (date) => {
  const newDate = new Date(date)
  return yearDateMonth(
    newDate.getDate(),
    newDate.getMonth(),
    newDate.getFullYear(),
  )
}

export const convertUTCYMD = (date) => {
  const newDate = new Date(date)
  return yearDateMonth(
    newDate.getUTCDate(),
    newDate.getUTCMonth(),
    newDate.getUTCFullYear(),
  )
}

export const stopLoader = (data, setShowLoader) => {
  if (data || equal(typeof data, 'undefined')) {
    setShowLoader(false)
  }
}

export const checkProjectDetailList = (item) => {
  const validationErrors = {}
  keys(item).forEach((name) => {
    const err = updateProjectDetailValidation(name, item[name])
    if (err && length(err)) {
      validationErrors[name] = err
    }
  })
  return validationErrors
}

export const projectDetailsWithReplace = (value) => {
  const string = projectDetailsKeyReplace.map((v, i) =>
    v.name === value
      ? value.replace(value, projectDetailsKeyReplace[i].replace)
      : null,
  )
  return string
}
